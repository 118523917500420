import { Controller } from "@hotwired/stimulus"
import Picker from "vanilla-picker";

// Connects to data-controller="color-picker"
export default class extends Controller {
  picker = null;

  connect() {
    this.picker = new Picker({ alpha: false });

    if (this.getPickerInputElement().value) {
      this.getPreviewElement().style.background = this.getPickerInputElement().value;
    } else {
      this.getPreviewElement().style.background = "white"
    }

    this.getPreviewElement().addEventListener("click", (e) => this.onPickerClick(e));
    // Clicking on the input to trigger the picker disable the ability to type the hex color by hand
    // this.getPickerInputElement().addEventListener("click", (e) => this.onPickerClick(e));

    this.picker.onChange = (color) => {
      this.getPreviewElement().style.background = color.hex.substring(0, 7);
      this.getPickerInputElement().value = color.hex.substring(0, 7);
    };

    this.picker.onClose = () => {
      this.picker.movePopup({ parent: null }, false);
    };
  }

  onPickerClick(e) {
    e.preventDefault();

    if (!(this.getPickerInputElement().disabled)) {
      this.picker.movePopup({ parent: this.getPreviewElement(), color: this.getPickerInputElement().value }, false);
      this.picker.openHandler(e);
    }
  }

  getPickerInputElement() {
    return this.element.querySelector('input[type="text"]');
  }

  getPreviewElement() {
    return this.element.querySelector('.preview');
  }
}
